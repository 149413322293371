import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Snackbar, VSpacing, VSpacingContainer } from '@hh.ru/magritte-ui';
import type { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import CareerPlatformBanner from 'src/components/CareerPlatformBanner';
import { useNotification } from 'src/components/Notifications/Provider';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { Topic, TopicAction, TopicActionName } from 'src/models/applicantNegotiationTopic.types';
import { ApplicantNegotiationFiltersStates } from 'src/models/applicantNegotiations/applicantNegotiationsActionsData';
import { CareerBannerPosition, CareerLinksKeys } from 'src/models/careerPlatformState';
import ApplicantNegotiationsListBanner from 'src/pages/ApplicantNegotiations/components/ApplicantNegotiations/ListBanner';
import doActionWithApplicantNegotiation from 'src/pages/ApplicantNegotiations/components/ApplicantNegotiations/doActionWithApplicantNegotiation';
import mapListOfObjects from 'src/pages/ApplicantNegotiations/utils/mapListOfObjects';

import ApplicantNegotiationListBatchAction from 'src/pages/ApplicantNegotiations/components/ApplicantNegotiationsMagritte/List/BatchAction';
import ApplicantNegotiationCard from 'src/pages/ApplicantNegotiations/components/ApplicantNegotiationsMagritte/List/Card';
import ConfirmationAlert, {
    ConfirmationAlertAction,
    ConfirmationAlertProps,
} from 'src/pages/ApplicantNegotiations/components/ApplicantNegotiationsMagritte/List/ConfirmationAlert';
import ApplicantNegotiationListPagination from 'src/pages/ApplicantNegotiations/components/ApplicantNegotiationsMagritte/List/Pagination';

const BANNER_PLACE_INDEX = 4;

const TrlKeys = {
    decline: {
        complete: 'applicant.negotiations.decline.complete',
    },
};

const ApplicantNegotiationList: TranslatedComponent = ({ trls }) => {
    const [selectedTopicIds, setSelectedTopicIds] = useState<Topic['id'][]>([]);
    const [areActionsDisabled, setActionsDisabled] = useState(false);
    const [alertProps, setAlertProps] = useState<Omit<ConfirmationAlertProps, 'onClose'>>({
        isVisible: false,
        action: null,
        onConfirm: null,
    });

    const [isDeclineInfoSnackbarVisible, setDeclineInfoSnackbarVisible] = useState(false);

    const { addNotification } = useNotification();
    const dispatch = useDispatch();

    const search = useSelector((state) => state.router.location.search);

    const { topicList: topics = [], paging } = useSelector((state) => state.applicantNegotiations);
    const deleteAction = useSelector((state) => state.applicantNegotiationsActionsData.deleteAction);
    const vacancies = useSelector((state) => state.vacanciesShort.vacanciesList);
    const managerActivities = useSelector((state) => state.applicantEmployerManagersActivity);
    const activeFilterTab = useSelector((state) => state.applicantNegotiationsCounters.activeFilterTab);
    const careerLinks = useSelector((state) => state.careerPlatformLinks);

    const VacancyMap = useMemo(() => mapListOfObjects(vacancies, 'vacancyId'), [vacancies]);
    const ManagerActivityMap = useMemo(() => mapListOfObjects(managerActivities, '@managerHhid'), [managerActivities]);

    const isAllSelected = selectedTopicIds.length === topics.length;
    const isSomeSelected = !!(selectedTopicIds.length && !isAllSelected);
    const isBatchActionVisible =
        topics?.length > 0 &&
        !(
            activeFilterTab === ApplicantNegotiationFiltersStates.Deleted ||
            activeFilterTab === ApplicantNegotiationFiltersStates.Archived
        );

    const changeStatus = useCallback(
        (action: TopicAction, topicId: Topic['id']) => {
            const { method, href, substate } = action;
            setActionsDisabled(true);
            dispatch(
                doActionWithApplicantNegotiation(
                    {
                        method,
                        href,
                        substate,
                        topicId,
                        search,
                    },
                    addNotification
                )
            )
                .then(() => {
                    if (action.name === TopicActionName.Decline) {
                        setDeclineInfoSnackbarVisible(true);
                    }
                })
                .finally(() => setActionsDisabled(false));
        },
        [addNotification, dispatch, search]
    );

    // В коллбек потому что дальше используем в useMemo
    const handleStatusChange = useCallback(
        (action: TopicAction, topicId: Topic['id']): void => {
            if (action.name === TopicActionName.Delete) {
                setAlertProps({
                    isVisible: true,
                    action: ConfirmationAlertAction.Delete,
                    onConfirm: () => {
                        changeStatus(action, topicId);

                        if (selectedTopicIds.includes(topicId)) {
                            setSelectedTopicIds(
                                selectedTopicIds.filter((selectedTopicId) => selectedTopicId !== topicId)
                            );
                        }
                    },
                });

                return;
            }
            if (action.name === TopicActionName.Decline) {
                setAlertProps({
                    isVisible: true,
                    action: ConfirmationAlertAction.Decline,
                    onConfirm: () => {
                        changeStatus(action, topicId);
                    },
                });

                return;
            }

            changeStatus(action, topicId);
        },
        [changeStatus, selectedTopicIds]
    );

    const handleSelect = (id: Topic['id']): void => {
        setSelectedTopicIds((prev) => (prev.includes(id) ? prev.filter((selected) => selected !== id) : [...prev, id]));
    };

    const handleSelectAll = (): void => {
        setSelectedTopicIds((prev) => (prev.length ? [] : topics.map(({ id }) => id)));
    };

    const handleDeleteSelected = (): void => {
        if (deleteAction) {
            setAlertProps({
                isVisible: true,
                action: ConfirmationAlertAction.DeleteSelected,
                onConfirm: () => {
                    dispatch(
                        doActionWithApplicantNegotiation(
                            {
                                ...deleteAction,
                                topicId: selectedTopicIds,
                            },
                            addNotification
                        )
                    ).finally(() => {
                        setSelectedTopicIds([]);
                    });
                },
            });
        }
    };

    const handleAlertClose = (): void => {
        setAlertProps({ isVisible: false, action: null, onConfirm: null });
    };

    const topicWithFeedbackTip = topics.find((topic) =>
        topic.actions?.some((action) => action.name === TopicActionName.Feedback)
    );

    useEffect(() => {
        setSelectedTopicIds([]);
    }, [search]);

    return (
        <VSpacingContainer default={16} gteM={24}>
            {isBatchActionVisible && (
                <section style={{ boxSizing: 'border-box' }}>
                    <ApplicantNegotiationListBatchAction
                        isSomeSelected={isSomeSelected}
                        isAllSelected={isAllSelected}
                        onSelectAll={handleSelectAll}
                        onDeleteSelected={handleDeleteSelected}
                    />
                </section>
            )}
            <ul>
                {topics.map((topic, index) => {
                    const vacancyId = topic.vacancyId;
                    const vacancy = VacancyMap[vacancyId];
                    const employerLastActivity = ManagerActivityMap[vacancy?.employerManager['@hhid']];
                    const card = (
                        <ApplicantNegotiationCard
                            topic={topic}
                            employerLastActivity={employerLastActivity}
                            vacancyName={vacancy.name}
                            employerName={vacancy.company.visibleName}
                            employerLogo={vacancy.company.logos?.logo[0]['@url']}
                            isSelected={selectedTopicIds.includes(topic.id)}
                            areActionsDisabled={areActionsDisabled}
                            onSelect={handleSelect}
                            onStatusChange={handleStatusChange}
                            isFeedbackTipVisible={topic.id === topicWithFeedbackTip?.id}
                        />
                    );

                    return (
                        <li key={topic.id}>
                            {index !== 0 && <VSpacing default={16} gteM={24} />}

                            {index === BANNER_PLACE_INDEX && (
                                <>
                                    <ApplicantNegotiationsListBanner key={`banner-${BANNER_PLACE_INDEX}`} />
                                    <VSpacing default={16} gteM={24} />
                                </>
                            )}
                            {card}
                            {careerLinks && careerLinks.links && careerLinks.negotiation_id === topic.id && (
                                <>
                                    <VSpacing default={16} gteM={24} />
                                    <CareerPlatformBanner
                                        position={CareerBannerPosition.Negotiations}
                                        destination={CareerLinksKeys.Courses}
                                    />
                                </>
                            )}
                        </li>
                    );
                })}
            </ul>
            {paging && <ApplicantNegotiationListPagination paging={paging} />}
            <ConfirmationAlert {...alertProps} onClose={handleAlertClose} />
            {isDeclineInfoSnackbarVisible && (
                <Snackbar autohideTime={3000} showClose>
                    {trls[TrlKeys.decline.complete]}
                </Snackbar>
            )}
        </VSpacingContainer>
    );
};

export default translation(ApplicantNegotiationList);
