import { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

let loadBannerOnVisible = () => {};

if (!process.env.SSR) {
    loadBannerOnVisible = require('HH/LoadBannerOnVisibleModule').default;
}

class BannersGroup extends Component {
    static propTypes = {
        /** Индификаторы баннеров */
        banners: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string })),
        /** Название шаблона для баннеров */
        templateName: PropTypes.string,
        /** Нода в которую кладется баннер */
        children: PropTypes.node,
        /** Класс для групповой банерной ноды */
        groupClassNames: PropTypes.string,
    };

    static defaultProps = {
        templateName: 'HHC-Banners-Place-Template',
        groupClassNames: '',
    };

    componentDidMount() {
        // eslint-disable-next-line react/no-find-dom-node
        this.element = ReactDOM.findDOMNode(this);
        this.initBanners();
    }

    initBanners() {
        const { banners, templateName } = this.props;
        const bannerParams = {
            ids: banners.map(({ id }) => id),
            templateName,
        };
        loadBannerOnVisible(this.element, bannerParams);
    }

    render() {
        const { groupClassNames, children } = this.props;
        return <div className={groupClassNames}>{children}</div>;
    }
}

export default BannersGroup;
