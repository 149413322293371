import { Fragment, useEffect, useRef, useState, useMemo, useContext, MouseEvent, RefObject } from 'react';
import classnames from 'classnames';

import { ChatikContext } from '@hh.ru/chatik-integration';
import { MenuItem } from 'bloko/blocks/drop';
import ClickMenu, { MenuPlacement } from 'bloko/blocks/drop/Menu/Click';
import HoverTip, { TipPlacement } from 'bloko/blocks/drop/Tip/HoverTip';
import {
    BubbleScaleSmallKindSingleAppearanceFilledShapeSquare,
    EllipsisScaleSmallKindHorizontal,
    IconDynamic,
    IconLink,
    IconColor,
} from 'bloko/blocks/icon';
import BlokoLink, { LinkAppearance, LinkKind } from 'bloko/blocks/link';
import Text, { TextImportance } from 'bloko/blocks/text';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import requestAnimation from 'bloko/common/requestAnimation';

import CareerPlatformBanner from 'src/components/CareerPlatformBanner';
import HumanDate from 'src/components/HumanDate';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { Topic, TopicActionName } from 'src/models/applicantNegotiationTopic.types';
import { ManagerActivityData } from 'src/models/applicantNegotiations/applicantEmployerManagersActivity';
import { CareerBannerPosition, CareerLinksKeys } from 'src/models/careerPlatformState';
import { VacancySearchItem } from 'src/models/vacancySearch/vacancySearchItem.types';
import getCallDuration from 'src/utils/callTracking/utils';

import ApplicantNegotiationsListAction from 'src/pages/ApplicantNegotiations/components/ApplicantNegotiations/ListAction';
import ApplicantNegotiationsListStatsColumn from 'src/pages/ApplicantNegotiations/components/ApplicantNegotiations/ListStatsColumn';
import ApplicantNegotiationsListTopicStatus from 'src/pages/ApplicantNegotiations/components/ApplicantNegotiations/ListTopicStatus';
import PendingAutoActionSurveyTag from 'src/pages/ApplicantNegotiations/components/ApplicantNegotiations/PendingAutoActionSurveyTag';
import ApplicantNegotiationsViewSummaryAction from 'src/pages/ApplicantNegotiations/components/ApplicantNegotiations/ViewSummaryAction';

import styles from './responses.less';

interface ShowActions {
    decline: boolean;
    delete: boolean;
    restore: boolean;
    feedback: boolean;
    vacancySummary: boolean;
}

type AugmentedTopicAction = TopicActionName | 'vacancySummary';

const shouldShowActionsInMenu = (actionNames: Record<AugmentedTopicAction, RefObject<HTMLElement>>) => {
    return Object.entries(actionNames).reduce((acc, [actionName, ref]) => {
        acc[actionName as TopicActionName] = ref.current !== null && ref.current.offsetTop > 0;
        return acc;
    }, {} as ShowActions);
};

interface ListRowProps {
    /** Объект с полями топика */
    topic: Topic;
    /** Объект вакансия соответсвующая текущему топику */
    vacancy: VacancySearchItem;
    /** Объект активности менеджера */
    managerInactiveData: ManagerActivityData;
    /** Хендлер измения топика */
    topicChange: (params: { checked: boolean; topicId: number }) => void;
    /** Флаг выбора топика */
    selected: boolean;
    /** Флаг выбран ли фильтр активных откликов */
    hasActiveFilter: boolean;
    showFeedbackTip: boolean;
}

const TrlKeys = {
    in: 'applicant.negotiations.in',
    sec: 'sec',
    min: 'min',
    callResponse: 'applicant.negotiations.callResponse',
};

const ListRow: TranslatedComponent<ListRowProps> = ({
    topic,
    vacancy,
    managerInactiveData,
    topicChange,
    selected,
    hasActiveFilter,
    trls,
    showFeedbackTip,
}) => {
    const careerLinks = useSelector((state) => state.careerPlatformLinks);
    const showCareerBanner = Boolean(careerLinks && careerLinks.links && topic.id === careerLinks.negotiation_id);
    const openChatik = useContext(ChatikContext)?.openChatik;
    const hhtmSource = useSelector(({ analyticsParams }) => analyticsParams.hhtmSource);
    const topicCallHistory = useSelector((state) => state.topicsCallHistory[topic.id]);
    const [unread, setUnread] = useState(topic.hasNewMessages);

    const declineRef = useRef<HTMLSpanElement>(null);
    const deleteRef = useRef<HTMLSpanElement>(null);
    const restoreRef = useRef<HTMLSpanElement>(null);
    const feedbackRef = useRef<HTMLSpanElement>(null);
    const vacancySummaryRef = useRef<HTMLSpanElement>(null);
    const refMap = useMemo(
        () => ({
            decline: declineRef,
            delete: deleteRef,
            restore: restoreRef,
            feedback: feedbackRef,
            vacancySummary: vacancySummaryRef,
        }),
        []
    );

    const [showMenu, setShowMenu] = useState(false);
    const [showMenuActivator, setShowMenuActivator] = useState(false);
    const [showActionsInMenu, setShowActionsInMenu] = useState({
        decline: false,
        delete: false,
        restore: false,
        feedback: false,
        vacancySummary: false,
    });

    /**
     * Если работодатель заблокирован за нарушение правил —
     * из действий оставляем только "Удалить"
     */
    const topicActions = topic.employerViolatesRules
        ? topic.actions?.filter((action) => action.name === TopicActionName.Delete)
        : topic.actions;

    useEffect(() => {
        const showMenuIfNeeded = requestAnimation(() => {
            const newMenu = shouldShowActionsInMenu({ ...refMap });
            setShowActionsInMenu(newMenu);
            setShowMenuActivator(Object.values(newMenu).includes(true));
        });

        showMenuIfNeeded();
        window.addEventListener('resize', showMenuIfNeeded);

        const menuElementsResizeObserver = new ResizeObserver(() => {
            showMenuIfNeeded();
        });

        const elementsForObserve: HTMLSpanElement[] = [];

        Object.keys(refMap).forEach((key) => {
            const ref = refMap[key];
            if (ref.current) {
                elementsForObserve.push(ref.current);
            }
        });

        elementsForObserve.forEach((ref) => {
            menuElementsResizeObserver.observe(ref);
        });

        return () => {
            elementsForObserve.forEach((ref) => {
                menuElementsResizeObserver.unobserve(ref);
            });
            window.removeEventListener('resize', showMenuIfNeeded);
        };
    }, [refMap, showMenuActivator]);

    const renderMenu = () => (
        <IconDynamic>
            <ClickMenu
                placement={MenuPlacement.BottomEnd}
                show={showMenu}
                render={() => (
                    <Fragment>
                        {topicActions?.map((action) => (
                            <Fragment key={action.name}>
                                {showActionsInMenu[action.name] && (
                                    <MenuItem>
                                        <ApplicantNegotiationsListAction
                                            action={action}
                                            topicId={topic.id}
                                            showFeedbackTip={showFeedbackTip}
                                        />
                                    </MenuItem>
                                )}
                            </Fragment>
                        ))}
                        {showActionsInMenu.vacancySummary && (
                            <MenuItem>
                                <ApplicantNegotiationsViewSummaryAction topicId={topic.id} />
                            </MenuItem>
                        )}
                    </Fragment>
                )}
            >
                <IconLink
                    Element="button"
                    type="button"
                    onClick={(event: MouseEvent) => {
                        event.stopPropagation();
                        setShowMenu(!showMenu);
                    }}
                >
                    <EllipsisScaleSmallKindHorizontal initial={IconColor.Gray40} />
                </IconLink>
            </ClickMenu>
        </IconDynamic>
    );

    const topicOnclick = () => {
        setUnread(false);
        openChatik?.({
            chatId: topic.chatId,
            view: 'widget',
            hhtmFromLabel: hhtmSource,
        });
    };

    const renderVacancyName = () => {
        if (topic.cantBeOpenedWithCurrentResume) {
            return <span data-qa="negotiations-item-vacancy">{vacancy.name}</span>;
        }

        return (
            <BlokoLink
                appearance={LinkAppearance.Pseudo}
                kind={topic.archived ? LinkKind.Secondary : undefined}
                data-qa="negotiations-item-vacancy"
            >
                {vacancy.name}
            </BlokoLink>
        );
    };

    const callDuration = getCallDuration(topicCallHistory, {
        sec: trls[TrlKeys.sec],
        min: trls[TrlKeys.min],
    });

    return (
        <>
            <tr
                className={classnames(styles.responsesTableRow, {
                    [styles.responsesTableRowWithCheckbox]: hasActiveFilter,
                    [styles.responsesTableRowUnread]: unread,
                    [styles.responsesTableRowSelected]: selected && hasActiveFilter,
                })}
                data-qa="negotiations-item"
                onClick={topicOnclick}
            >
                <td className={styles.responsesTableRowCell}>
                    <ApplicantNegotiationsListStatsColumn
                        selected={selected}
                        topic={topic}
                        topicChange={topicChange}
                        hasActiveFilter={hasActiveFilter}
                        managerInactiveData={managerInactiveData}
                    />
                </td>
                <td className={classnames(styles.responsesTableRowCell, styles.responsesTableRowCellIcon)}>
                    {unread && topic.hasNewMessages && (
                        <BubbleScaleSmallKindSingleAppearanceFilledShapeSquare initial={IconColor.Green60} />
                    )}
                </td>
                <td className={classnames(styles.responsesTableRowCell, styles.responsesTableRowCellVacancy)}>
                    {renderVacancyName()}
                    <div className={styles.responsesTableRowEmployer}>
                        <Text importance={TextImportance.Tertiary}>
                            {trls[TrlKeys.in]}
                            <span data-qa="negotiations-item-company"> {vacancy.company.visibleName}</span>
                        </Text>
                    </div>
                    {topic.hasPendingAutoActionSurvey && <PendingAutoActionSurveyTag topicId={topic.id} />}
                    <div className={styles.responsesTableRowVacancyDetails}>
                        <div className={styles.responsesTableActionsAndMenuContainer}>
                            <div className={styles.responsesTableActions}>
                                {topicActions?.map?.((action) => (
                                    <span
                                        onClick={(e) => e.stopPropagation()}
                                        className={styles.responsesTableForm}
                                        key={action.name}
                                        ref={refMap[action.name]}
                                    >
                                        <ApplicantNegotiationsListAction
                                            action={action}
                                            topicId={topic.id}
                                            showFeedbackTip={showFeedbackTip && !showActionsInMenu.feedback}
                                        />
                                    </span>
                                ))}
                                {!topic.employerViolatesRules && topic.applicantVacancySummaryEnabled && (
                                    <span className={styles.responsesTableForm} ref={vacancySummaryRef}>
                                        <ApplicantNegotiationsViewSummaryAction topicId={topic.id} />
                                    </span>
                                )}
                            </div>
                            <div>{!topic.employerViolatesRules && showMenuActivator && renderMenu()}</div>
                        </div>
                        <span className={styles.responsesTableDateShowOnXs}>
                            <HumanDate date={topic.lastModifiedMillis} textify />
                        </span>
                    </div>
                </td>
                <td
                    className={classnames(
                        styles.responsesTableRowCell,
                        styles.responsesTableRowCellVacancyStatus,
                        styles.responsesTableRowCellNowrap
                    )}
                >
                    <ApplicantNegotiationsListTopicStatus archived={topic.archived} lastSubState={topic.lastSubState} />
                </td>
                <td
                    className={classnames(
                        styles.responsesTableRowCell,
                        styles.responsesTableRowCellDate,
                        styles.responsesTableRowCellNowrap,
                        { [styles.responsesTableRowCellFailed]: topicCallHistory?.pickedUpPhone === false }
                    )}
                >
                    {!topicCallHistory ? (
                        <div className={classnames(styles.responseTableIcon, styles.responseTableIconManually)} />
                    ) : (
                        <HoverTip
                            host={!process.env.SSR ? document.body : null}
                            placement={TipPlacement.Top}
                            render={() => trls[TrlKeys.callResponse]}
                        >
                            <div
                                className={classnames(styles.responseTableIcon, {
                                    [styles.responseTableIconSuccess]: topicCallHistory.pickedUpPhone,
                                    [styles.responseTableIconFailed]: !topicCallHistory.pickedUpPhone,
                                })}
                            />
                        </HoverTip>
                    )}
                    <span data-qa="negotiations-item-date">
                        <HumanDate date={topic.lastModifiedMillis} textify />
                    </span>
                    {callDuration && <span> • {callDuration}</span>}
                </td>
            </tr>
            {showCareerBanner && (
                <tr className={styles.responsesTableRowCareer}>
                    <td colSpan={5}>
                        <CareerPlatformBanner
                            position={CareerBannerPosition.Negotiations}
                            destination={CareerLinksKeys.Courses}
                        />
                    </td>
                </tr>
            )}
        </>
    );
};

export default translation(ListRow);
