import { Component, ReactNode, forwardRef, ForwardedRef, ForwardRefRenderFunction } from 'react';
import ReactDOM from 'react-dom';
import { connect, DefaultRootState } from 'react-redux';

import LoadBannerOnVisibleSignature from 'HH/LoadBannerOnVisibleModule.d';
import { YandexAdfoxBannerProps } from 'src/models/banners/YandexAdfoxBanner.types';
import { UserTargeting } from 'src/models/userTargeting';

let loadBannerOnVisible: typeof LoadBannerOnVisibleSignature | undefined;

if (!process.env.SSR) {
    /* eslint @typescript-eslint/no-var-requires: "off" */
    /* eslint @typescript-eslint/no-unsafe-member-access: "off" */
    loadBannerOnVisible = require('HH/LoadBannerOnVisibleModule').default as typeof LoadBannerOnVisibleSignature;
}

interface BannerProps {
    /** Индификатор баннера */
    id: string;
    /** Название шаблона для баннера */
    templateName?: string;
    /** Нода в которую кладется баннер */
    children?: ReactNode;
    /** Флаг отмены отправки запросов в баннерную систему */
    cancelFetchBanners?: boolean;
    /** Строка текущих get параметров URL */
    search?: string;
    /** Строка текущего URL без параметров */
    pathname?: string;
    /** Параметры для Yandex adfox баннеров */
    adfoxParams?: Omit<YandexAdfoxBannerProps, 'id'>;
    /** Строит пустой див вместо баннера **/
    isEmpty?: boolean;
    userTargeting?: UserTargeting;
    fwdRef?: ForwardedRef<HTMLDivElement>;
}

class BannerRaw extends Component<BannerProps> {
    element: HTMLElement | null = null;
    static defaultProps = {
        templateName: 'HHC-Banners-Place-Template',
    };

    componentDidMount() {
        // eslint-disable-next-line react/no-find-dom-node
        this.element = this.props.isEmpty ? null : (ReactDOM.findDOMNode(this) as HTMLElement);
        if (this.element) {
            this.element.addEventListener('HH-Banners-Init', this.setAutoHeight.bind(this));
            this.initBanners();
        }
    }

    componentDidUpdate() {
        if (this.element) {
            this.initBanners();
        }
    }

    componentWillUnmount() {
        this.element?.removeEventListener('HH-Banners-Init', this.setAutoHeight);
    }

    shouldComponentUpdate(nextProps: BannerProps) {
        if (nextProps.cancelFetchBanners) {
            return false;
        }
        return nextProps.search !== this.props.search || nextProps.pathname !== this.props.pathname;
    }

    initBanners() {
        const { id, templateName, adfoxParams, userTargeting } = this.props;
        if (this.element) {
            this.element.style.height = `${this.element.clientHeight}px`;
            this.element.innerHTML = '';
        }
        let adfoxParamsProperty;
        if (adfoxParams) {
            adfoxParamsProperty = {
                ...adfoxParams,
                type: 'adfox',
                bannerId: id,
                place: id,
                bannerCommonTargeting: userTargeting,
                bannerAdTargeting: adfoxParams.bannerAdTargeting || {},
            };
        }
        const bannerParams = {
            id,
            templateName,
            adfoxParams: adfoxParamsProperty,
        };

        loadBannerOnVisible?.(this.element, bannerParams);
    }

    setAutoHeight = (): void => {
        if (this.element) {
            this.element.style.height = 'auto';
        }
    };

    render() {
        return this.props.children || <div ref={this.props.fwdRef} />;
    }
}

const Banner: ForwardRefRenderFunction<HTMLDivElement, BannerProps> = (props, ref) => (
    <BannerRaw {...props} fwdRef={ref} />
);

export default connect(
    (state: DefaultRootState) => ({
        pathname: state.router.location.pathname,
        search: state.router.location.search,
        cancelFetchBanners: state.cancelFetchBanners,
        userTargeting: state.userTargeting,
    }),
    null,
    null,
    { forwardRef: true }
)(forwardRef(Banner));
